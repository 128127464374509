<template>
  <div class="card border-0">
    <div class="card-header f-w-600">证照情况</div>
    <div class="card-body height-auto m-0 p-0">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >证照类型：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <dictSelect
                  :show="false"
                  @select="DictIdSelect"
                  :value="Businessfile.dictId"
                  :pid="'3a03aa2d-bddc-8734-3d3e-ff1029d7c61c'"
                ></dictSelect>
              </div>
            </div>
            <!-- <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >证照类型：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Businessfile.dictId"
                />
              </div>
            </div> -->
            <div class="form-group row">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >照片：</label
              >
              <div class="page-content col-lg-9 col-md-9">
                <div>
                  <upload
                    :maxSize="2"
                    :maxFiles="2"
                    :value="Businessfile.fileId"
                    @finishUpload="finishAll"
                  >
                  </upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="checkForm"
        >
          <i class="fa fa-save"></i> 保存
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
import dictSelect from "@/components/dictSelect/index.vue";
import { dictApi } from "@/api/system/dictApi";
export default {
  props: ["Businessfileid"],
  components: {
    dictSelect,
  },
  data() {
    return {
      Businessfile: {
        businessId: "",
        dictId: "",
        fileId: [],
        dictName: "",
      },
    };
  },
  created() {},
  mounted() {
    //编辑时获取证照信息
    // this.GetBusinessfile();
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      const params = {
        businessId: this.$route.query.id,
        dictId: this.Businessfile.dictName,
        FileId: this.Businessfile.fileId.toString(),
      };

      //e.preventDefault();
      let that = this;
      if (that.Businessfileid) {
        businessApi
          .UpdateBusinessfile(params)
          .then((res) => that.UpdateBusinessfileSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreateBusinessfile(params)
          .then((res) => that.CreateBusinessfileSuccess(that, res))
          .finally(() => {});
      }
    },

    // GetBusinessfile() {
    //   let that = this;
    //   businessApi
    //     .GetBusinessfile({
    //       //入参为地址栏传入的ID值
    //       id: that.Businessfileid,
    //     })
    //     .then((res) => that.GetBusinessfileSuccess(that, res))
    //     .finally(() => {});
    // },
    // GetBusinessfileSuccess(that, res) {
    //   if (res.success) {
    //     // console.log(res.data);
    //     that.Businessfile = res.data;
    //     // 把证件ID存到数组中让组件读取
    //     // that.Businessfile.fileId=["3a0386b6-b564-d38c-422d-e14539d52458", "3a0386b6-b07e-3c12-51b2-c61b5d792f70"]
    //     (that.Businessfile.fileId = [res.data.fileId])
    //   } else {
    //     console.log(res);
    //   }
    // },
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },

    CreateBusinessfileSuccess(that, res) {
      if (res.success) {
        this.ToastSuccess(res);
        this.$emit("close");
      } else {
        this.ToastWarning(res);
      }
    },
    UpdateBusinessfileSuccess(that, res) {
      if (res.success) {
        this.ToastSuccess(res);
        this.$emit("close");
      } else {
        this.ToastWarning(res);
      }
    },
    //证照类型选择框返回值
    DictIdSelect(id) {
      this.Businessfile.dictId = id;
      dictApi
        .getDict({
          id: id,
        })
        .then((res) => {
          // console.log(res);
          if (res != undefined && res.success && res.data != undefined) {
            this.Businessfile.dictName = res.data.name;
          }
        })

        .finally(() => {});
      console.log("dictId", id);
    },
    finishAll(e) {
      this.Businessfile.fileId = e;
      console.log(e);
    },
  },
};
</script>
